import './register-success.scss';
import React, { useEffect } from 'react';

export const RegisterSuccess = props => {
  
  setTimeout(() => {
    props.history.push('/');
  }, 2000);

  return (
    <div className="registerSuccess">
      <h3>注册成功！</h3>
      <p>
        当前正在为您跳转页面，若跳转不成功请点击<a href="/">链接</a>
      </p>
    </div>
  );
};

export default RegisterSuccess;
