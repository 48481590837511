import { Moment } from 'moment';

export interface ITransaction {
  id?: number;
  type?: string;
  hash?: string;
  timestamp?: string;
  sender?: string;
  receiver?: string;
  status?: boolean;
}

export const defaultValue: Readonly<ITransaction> = {
  status: false,
};
