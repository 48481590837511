import { IDataAnalysis } from 'app/shared/model/data-analysis.model';
import { IMembership } from 'app/shared/model/membership.model';
import { OrganizationRole } from 'app/shared/model/enumerations/organization-role.model';

export interface IDataAnalysisAccess {
  id?: number;
  role?: OrganizationRole;
  dataAnalysis?: IDataAnalysis;
  membership?: IMembership;
}

export const defaultValue: Readonly<IDataAnalysisAccess> = {};
