import { Moment } from 'moment';
import { ICohort } from 'app/shared/model/cohort.model';
import { DictFileType } from 'app/shared/model/enumerations/dict-file-type.model';

export interface IDictUploadHistory {
  id?: number;
  uploadAt?: string;
  type?: DictFileType;
  contentContentType?: string;
  content?: any;
  cohort?: ICohort;
}

export const defaultValue: Readonly<IDictUploadHistory> = {};
