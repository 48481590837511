import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { OrganizationRole } from 'app/shared/model/enumerations/organization-role.model';
import { RequestStatus } from 'app/shared/model/enumerations/request-status.model';

export interface IRequestJoinOrg {
  id?: number;
  role?: OrganizationRole;
  status?: RequestStatus;
  userProfile?: IUserProfile;
  organization?: IOrganization;
  createdDate?: string;
}

export const defaultValue: Readonly<IRequestJoinOrg> = {};
