import React from 'react';
import { handlePasswordReset } from 'app/modules/account/password-reset/password-reset.reducer.ts';
import ResetPassword from 'app/ddex/modules/reset-password';

export const ResetPasswordFinish = props => {
  const { location, history } = props;
  return <ResetPassword title="重置密码" location={location} history={history} resetPassword={handlePasswordReset}></ResetPassword>;
};

export default ResetPasswordFinish;
