import { Moment } from 'moment';
import { ICohortAccess } from 'app/shared/model/cohort-access.model';
import { ICohortDictRecord } from 'app/shared/model/cohort-dict-record.model';
import { ICooperationCohort } from 'app/shared/model/cooperation-cohort.model';
import { IOrganization } from 'app/shared/model/organization.model';

export interface ICohort {
  id?: number;
  name?: string;
  cohortID?: string;
  cohortType?: string;
  questionnaireType?: string;
  website?: string;
  principalOrg?: string;
  principalAddr?: string;
  principalName?: string;
  principalEmail?: string;
  principalPhone?: string;
  principalTitle?: string;
  leadUnit?: string;
  contactOrg?: string;
  contactAddr?: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactTitle?: string;
  funding?: any;
  purpose?: any;
  startDate?: string;
  endDate?: string;
  count?: number;
  detailedCount?: any;
  age?: string;
  area?: string;
  sampling?: any;
  inclusion?: any;
  exclusion?: any;
  result?: any;
  article?: any;
  advantage?: any;
  limit?: any;
  notes?: any;
  cohortAccesses?: ICohortAccess[];
  cohortDictRecords?: ICohortDictRecord[];
  cooperationCohorts?: ICooperationCohort[];
  organization?: IOrganization;
}

export const defaultValue: Readonly<ICohort> = {};
