import axios from 'axios';
import { ICrudSearchAction, IPayload, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICohort, defaultValue } from 'app/shared/model/cohort.model';

export const ACTION_TYPES = {
  SEARCH_COHORTS: 'cohort/SEARCH_COHORTS',
  SEARCH_COHORTS_V1: 'cohort/SEARCH_COHORTS_V1',
  FETCH_COHORT_LIST: 'cohort/FETCH_COHORT_LIST',
  FETCH_COHORT_MEMBERS: 'cohort/FETCH_COHORT_MEMBERS',
  FETCH_DICTIONARY_LIST: 'cohort/FETCH_DICTIONARY_LIST',
  FETCH_COHORT_NUMBERS: 'cohort/FETCH_COHORT_NUMBERS',
  FETCH_USER_NUMBERS: 'cohort/FETCH_USER_NUMBERS',
  FETCH_DICT_NUMBERS: 'cohort/FETCH_DICT_NUMBERS',
  FETCH_COUNT_NUMBERS: 'cohort/FETCH_COUNT_NUMBERS',
  FETCH_COHORT: 'cohort/FETCH_COHORT',
  FETCH_CURRENT_COHORT: 'cohort/FETCH_CURRENT_COHORT',
  FETCH_USER_ROLE: 'cohort/FETCH_USER_ROLE',
  CREATE_COHORT: 'cohort/CREATE_COHORT',
  UPDATE_COHORT: 'cohort/UPDATE_COHORT',
  UPDATE_MEMBER_PERMISSION: 'cohort/UPDATE_MEMBER_PERMISSION',
  DELETE_COHORT: 'cohort/DELETE_COHORT',
  SET_BLOB: 'cohort/SET_BLOB',
  RESET: 'cohort/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICohort>,
  cohortMembers: [],
  totalItems: 0,
  dictionary: [],
  dictionaryTotal: 0,
  cohortNumbers: 0,
  userNumbers: 0,
  dictNumbers: 0,
  countNumbers: 0,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  currentCohortUpdateSuccess: false,
  userRole: null,
};

export type CohortState = Readonly<typeof initialState>;
export type CohortDictionSearchAction<T> = (
  search?: string,
  cohortType?: string,
  questionnaireType?: string,
  page?: number,
  size?: number,
  sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

// Reducer

// eslint-disable-next-line complexity
export default (state: CohortState = initialState, action): CohortState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_COHORTS):
    case REQUEST(ACTION_TYPES.SEARCH_COHORTS_V1):
    case REQUEST(ACTION_TYPES.FETCH_COHORT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_COHORT_MEMBERS):
    case REQUEST(ACTION_TYPES.FETCH_COHORT_NUMBERS):
    case REQUEST(ACTION_TYPES.FETCH_USER_NUMBERS):
    case REQUEST(ACTION_TYPES.FETCH_DICT_NUMBERS):
    case REQUEST(ACTION_TYPES.FETCH_COUNT_NUMBERS):
    case REQUEST(ACTION_TYPES.FETCH_COHORT):
    case REQUEST(ACTION_TYPES.FETCH_USER_ROLE):
    case REQUEST(ACTION_TYPES.FETCH_DICTIONARY_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_CURRENT_COHORT):
      return {
        ...state,
        currentCohortUpdateSuccess: false,
      };
    case REQUEST(ACTION_TYPES.CREATE_COHORT):
    case REQUEST(ACTION_TYPES.UPDATE_COHORT):
    case REQUEST(ACTION_TYPES.UPDATE_MEMBER_PERMISSION):
    case REQUEST(ACTION_TYPES.DELETE_COHORT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_COHORTS):
    case FAILURE(ACTION_TYPES.SEARCH_COHORTS_V1):
    case FAILURE(ACTION_TYPES.FETCH_COHORT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_COHORT_MEMBERS):
    case FAILURE(ACTION_TYPES.FETCH_COHORT_NUMBERS):
    case FAILURE(ACTION_TYPES.FETCH_USER_NUMBERS):
    case FAILURE(ACTION_TYPES.FETCH_DICT_NUMBERS):
    case FAILURE(ACTION_TYPES.FETCH_COUNT_NUMBERS):
    case FAILURE(ACTION_TYPES.FETCH_COHORT):
    case FAILURE(ACTION_TYPES.FETCH_USER_ROLE):
    case FAILURE(ACTION_TYPES.FETCH_DICTIONARY_LIST):
    case FAILURE(ACTION_TYPES.CREATE_COHORT):
    case FAILURE(ACTION_TYPES.UPDATE_COHORT):
    case FAILURE(ACTION_TYPES.UPDATE_MEMBER_PERMISSION):
    case FAILURE(ACTION_TYPES.DELETE_COHORT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_COHORTS):
    case SUCCESS(ACTION_TYPES.SEARCH_COHORTS_V1):
    case SUCCESS(ACTION_TYPES.FETCH_COHORT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_COHORT_MEMBERS):
      return {
        ...state,
        loading: false,
        cohortMembers: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DICTIONARY_LIST):
      return {
        ...state,
        loading: false,
        dictionary: action.payload.data,
        dictionaryTotal: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_COHORT_NUMBERS):
      return {
        ...state,
        loading: false,
        cohortNumbers: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER_NUMBERS):
      return {
        ...state,
        loading: false,
        userNumbers: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DICT_NUMBERS):
      return {
        ...state,
        loading: false,
        dictNumbers: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_COUNT_NUMBERS):
      return {
        ...state,
        loading: false,
        countNumbers: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_COHORT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CURRENT_COHORT):
      return {
        ...state,
        currentCohortUpdateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER_ROLE):
      return {
        ...state,
        loading: false,
        userRole: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_COHORT):
    case SUCCESS(ACTION_TYPES.UPDATE_COHORT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_MEMBER_PERMISSION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.DELETE_COHORT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/cohorts';
const apiSearchUrl = 'api/_search/cohorts';
const apiV1SearchUrl = 'api/v1/_search';
const apiExtendedUrl = 'api/v1/cohort';
const apiUserNumbersUrl = 'api/v1/user/userNumbers';
const apiDictNumbersUrl = 'api/v1/cohort-dict-records/dictNumbers';

// Actions

export const getSearchEntities: ICrudSearchAction<ICohort> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_COHORTS,
  payload: axios.get<ICohort>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getV1SearchEntities: CohortDictionSearchAction<ICohort> = (query, cohortType, questionnaireType, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_COHORTS_V1,
  payload: axios.get<ICohort>(
    `${apiV1SearchUrl}?query=${query}&page=${page}&size=${size}${cohortType ? `&cohortType=${cohortType}` : ''}${
      questionnaireType ? `&questionnaireType=${questionnaireType}` : ''
    }`
  ),
});

export const getEntities: ICrudGetAllAction<ICohort> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_COHORT_LIST,
    payload: axios.get<ICohort>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getCohorts: ICrudGetAllAction<ICohort> = (page, size, sort) => {
  const requestUrl = `api/v1/cohorts${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_COHORT_LIST,
    payload: axios.get<ICohort>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getCurrentUserEntities: ICrudGetAllAction<ICohort> = (page, size, sort) => {
  const requestUrl = `api/v1/current-user/cohorts${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_COHORT_LIST,
    payload: axios.get<ICohort>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getExtendedEntities: ICrudGetAllAction<ICohort> = (page, size, sort) => {
  const requestUrl = `api/v1/brief/cohorts${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_COHORT_LIST,
    payload: axios.get<ICohort>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getDictionary: ICrudSearchAction<ICohort> = (id, page, size, sort) => {
  const requestUrl = `${apiExtendedUrl}/${id}/dictionary${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_DICTIONARY_LIST,
    payload: axios.get<ICohort>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getCohortNumbers: ICrudGetAllAction<ICohort> = () => {
  const requestUrl = `${apiExtendedUrl}/cohortNumbers`;
  return {
    type: ACTION_TYPES.FETCH_COHORT_NUMBERS,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const getUserNumbers: ICrudGetAllAction<ICohort> = () => {
  const requestUrl = `${apiUserNumbersUrl}`;
  return {
    type: ACTION_TYPES.FETCH_USER_NUMBERS,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const getDictNumbers: ICrudGetAllAction<ICohort> = () => {
  const requestUrl = `${apiDictNumbersUrl}`;
  return {
    type: ACTION_TYPES.FETCH_DICT_NUMBERS,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const getCountNumbers: ICrudGetAllAction<ICohort> = () => {
  const requestUrl = `${apiExtendedUrl}/countNumbers`;
  return {
    type: ACTION_TYPES.FETCH_COUNT_NUMBERS,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const getEntity: ICrudGetAction<ICohort> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_COHORT,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const getCohort: ICrudGetAction<ICohort> = id => {
  const requestUrl = `api/v1/cohorts/${id}`;
  return {
    type: ACTION_TYPES.FETCH_COHORT,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const getCurrentUserCohort: ICrudGetAction<ICohort> = id => {
  const requestUrl = `api/v1/current-user/cohorts/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CURRENT_COHORT,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const getCohortMembers: ICrudGetAction<ICohort> = id => {
  const requestUrl = `api/v1/cohort/cohort-members/${id}`;
  return {
    type: ACTION_TYPES.FETCH_COHORT_MEMBERS,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const getUserRole: ICrudGetAction<ICohort> = id => {
  const requestUrl = `api/v1/current-user/cohort/${id}/role`;
  return {
    type: ACTION_TYPES.FETCH_USER_ROLE,
    payload: axios.get<ICohort>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ICohort> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_COHORT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICohort> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_COHORT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
};

export const updateMemberPermission = (id, role, cohortId) => async dispatch => {
  const requestUrl = `api/v1/cohort-accesses/role/${id}?role=${role}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_MEMBER_PERMISSION,
    payload: axios.put(requestUrl),
  });
  dispatch(getCohortMembers(cohortId));
  return result;
};

export const updateExtendedEntity: ICrudPutAction<ICohort> = entity => async dispatch => {
  const requestUrl = `${apiExtendedUrl}/organization`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_COHORT,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICohort> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_COHORT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
