import { Moment } from 'moment';
import { IAnalysisVariable } from 'app/shared/model/analysis-variable.model';
import { IDataAnalysis } from 'app/shared/model/data-analysis.model';
import { ICohort } from 'app/shared/model/cohort.model';

export interface ICooperationCohort {
  id?: number;
  status?: number;
  applyTime?: string;
  analysisVariables?: IAnalysisVariable[];
  dataAnalysis?: IDataAnalysis;
  cohort?: ICohort;
}

export const defaultValue: Readonly<ICooperationCohort> = {};
