import './reset-password.scss';
import React, { useEffect, useRef, useState } from 'react';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import Loader from 'app/ddex/shared/model/loader.tsx';

export const ResetPassword = props => {
  const formRef = useRef(null);
  const { location, history, title, resetPassword } = props;
  const initializePasswordKey = location.search.slice(5);

  const resetPasswordSuccess = useSelector((state: IRootState) => state.passwordReset.resetPasswordSuccess);
  const loading = useSelector((state: IRootState) => state.passwordReset.loading);

  const [form, setForm] = useState({
    password: '',
    confirmPassword: '',
  });
  const [isChangePasswordIcon, setIsChangePasswordIcon] = useState(false);
  const [isConsistentPassword, setIsConsistentPassword] = useState(null);
  const [isInvalidPassword, setIsInvalidPassword] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState({
    characterValidation: false,
    letterValidation: false,
    numberValidation: false,
    specialSymbolValidation: false,
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useDispatch();

  const changeFormClass = (key, value) => {
    const className = formRef.current.className;
    const isHaveClassName = className.includes(value);
    switch (key) {
      case 'add':
        !isHaveClassName && (formRef.current.className += value);
        break;
      case 'remove':
        isHaveClassName && (formRef.current.className = className.replace(value, ''));
        break;
      default:
        break;
    }
  };

  const checkPassword = password => {
    const characterValidation = password.length >= 8;
    const letterValidation = /(?=.*[a-z])(?=.*[A-Z])/.test(password);
    const numberValidation = /\d/.test(password);
    const specialSymbolPattern = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
    const specialSymbolValidation = specialSymbolPattern.test(password);
    changeFormClass(characterValidation ? 'add' : 'remove', ' characterValidation');
    changeFormClass(letterValidation ? 'add' : 'remove', ' letterValidation');
    changeFormClass(numberValidation ? 'add' : 'remove', ' numberValidation');
    changeFormClass(specialSymbolValidation ? 'add' : 'remove', ' specialSymbolValidation');

    setPasswordValidation({ ...passwordValidation, characterValidation, letterValidation, numberValidation, specialSymbolValidation });
  };

  const checkConfirmPassword = (password, confirmPassword) => {
    setIsConsistentPassword(confirmPassword === password);
    if (confirmPassword === password) {
      confirmPassword !== '' && changeFormClass('add', ' consistentPassword');
      changeFormClass('remove', ' inconsistentPassword');
    } else {
      changeFormClass('remove', ' consistentPassword');
      confirmPassword === '' ? changeFormClass('remove', ' inconsistentPassword') : changeFormClass('add', ' inconsistentPassword');
    }
  };

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const submitHandler = event => {
    event.preventDefault();
    const entity = { key: initializePasswordKey, newPassword: form.password };
    dispatch(resetPassword(entity));
  };

  useEffect(() => {
    (!isInvalidPassword || form.password === '') && changeFormClass('remove', ' passwordInvalid');

    checkPassword(form.password);
    checkConfirmPassword(form.password, form.confirmPassword);

    const isFillAllInputs = Object.values(form).includes('');
    if (!isFillAllInputs && !isInvalidPassword && form.confirmPassword === form.password) setIsDisabled(false);
    else setIsDisabled(true);
  }, [form, isInvalidPassword]);

  useEffect(() => {
    setIsInvalidPassword(Object.values(passwordValidation).includes(false));
  }, [passwordValidation]);

  useEffect(() => {
    resetPasswordSuccess && history.push('/');
  }, [resetPasswordSuccess]);

  return (
    <div className="resetPassword">
      <h3>{title}</h3>
      <form className="needs-validation" onSubmit={submitHandler} autoComplete="off" noValidate ref={formRef}>
        <div className="inputGroup">
          <MDBInput
            icon={isConsistentPassword ? 'check' : 'times'}
            label="密码"
            group
            type="password"
            value={form.password}
            onChange={changeHandler}
            name="password"
            onBlur={event => {
              const target = event.target as HTMLInputElement;
              if (target.value !== '') {
                setIsChangePasswordIcon(true);
                isInvalidPassword && changeFormClass('add', ' passwordInvalid');
              }
            }}
          >
            <div className="passwordValidation">
              <div className="character">
                <MDBIcon icon={isChangePasswordIcon && !passwordValidation.characterValidation ? 'times-circle' : 'check-circle'} />
                包含8位及以上字符
              </div>
              <div className="letter">
                <MDBIcon icon={isChangePasswordIcon && !passwordValidation.letterValidation ? 'times-circle' : 'check-circle'} />
                包含大小写字母
              </div>
              <div className="number">
                <MDBIcon icon={isChangePasswordIcon && !passwordValidation.numberValidation ? 'times-circle' : 'check-circle'} />
                包含数字
              </div>
              <div className="specialSymbol">
                <MDBIcon icon={isChangePasswordIcon && !passwordValidation.specialSymbolValidation ? 'times-circle' : 'check-circle'} />
                包含特殊符号
              </div>
            </div>
          </MDBInput>

          <MDBInput
            icon={isConsistentPassword ? 'check' : 'times'}
            label="确认密码"
            group
            type="password"
            value={form.confirmPassword}
            onChange={changeHandler}
            name="confirmPassword"
          >
            <div className="invalid-feedback">密码不一致</div>
          </MDBInput>
        </div>
        <div className="text-right">
          <MDBBtn type="submit" disabled={isDisabled}>
            {loading ? <Loader></Loader> : '提交'}
          </MDBBtn>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
