import axios from 'axios';
import { ICrudGetAction, translate } from 'react-jhipster';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { ICohort } from 'app/shared/model/cohort.model';

export const ACTION_TYPES = {
  CREATE_ACCOUNT: 'register/CREATE_ACCOUNT',
  CHECK_EMAIL: 'register/CHECK_EMAIL',
  RESEND_VERIFICATION: 'register/RESEND_VERIFICATION',
  RESET: 'register/RESET',
};

const initialState = {
  loading: false,
  registrationSuccess: false,
  registrationFailure: false,
  errorMessage: null,
};

export type RegisterState = Readonly<typeof initialState>;

// Reducer
export default (state: RegisterState = initialState, action): RegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ACCOUNT):
    case REQUEST(ACTION_TYPES.CHECK_EMAIL):
    case REQUEST(ACTION_TYPES.RESEND_VERIFICATION):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationFailure: true,
        errorMessage: action.payload.response.data.errorKey,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        loading: false,
        registrationSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.CHECK_EMAIL):
      return {
        ...initialState,
        loading: false,
        errorMessage: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.RESEND_VERIFICATION):
      return {
        ...initialState,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
export const handleRegister = (login, email, password, langKey = 'en') => ({
  type: ACTION_TYPES.CREATE_ACCOUNT,
  payload: axios.post('api/register', { login, email, password, langKey }),
  meta: {
    successMessage: translate('register.messages.success'),
  },
});

export const extendedRegister = entity => ({
  type: ACTION_TYPES.CREATE_ACCOUNT,
  payload: axios.post('api/v1/register', entity),
});

export const resendEmailVerification = email => ({
  type: ACTION_TYPES.RESEND_VERIFICATION,
  payload: axios.post(`api/v1/account/reactivate/init?email=${email}`),
});

export const checkIsExistEmail = email => {
  const requestUrl = `api/v1/email-exist?email=${email}`;
  return {
    type: ACTION_TYPES.CHECK_EMAIL,
    payload: axios.get(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
