import { RequestStatus } from 'app/shared/model/enumerations/request-status.model';

export interface IApplication {
  id?: number;
  dataAnalysisId?: number;
  firstWorkGroup?: number;
  secondWorkGroup?: number;
  status?: RequestStatus;
}

export const defaultValue: Readonly<IApplication> = {};
