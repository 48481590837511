import './email-validation.scss';
import React, { useEffect, useState } from 'react';
import { MDBBtn } from 'mdbreact';
import { useDispatch } from 'react-redux';
import { resendEmailVerification } from 'app/modules/account/register/register.reducer.ts';

export const EmailValidation = props => {
  const { location } = props;
  const email = location.state.email;

  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState(true);
  const [isShowSecond, setIShowSecond] = useState(false);
  const [initSecond] = useState(60);
  const [second, setSecond] = useState(initSecond);

  const countdown = () => {
    setIsDisabled(true);
    setIShowSecond(true);
    for (let i = 1; i <= initSecond; ++i) {
      setTimeout(() => {
        setSecond(initSecond - i);
        if (i === initSecond) {
          setIsDisabled(false);
          setIShowSecond(false);
          setSecond(initSecond);
        }
      }, i * 1000);
    }
  };

  const submitHandler = () => {
    dispatch(resendEmailVerification(email));
    countdown();
  };

  useEffect(() => {
    countdown();
  }, []);

  return (
    <div className="emailValidation">
      <h3>电子邮箱验证</h3>
      <p>验证邮件已发送至您的电子邮箱：{email}。</p>
      <p>点击邮件中的激活链接即可继续注册。如果您没有收到验证邮件，请点击“重新发送验证”。</p>
      <MDBBtn disabled={isDisabled} onClick={submitHandler} className="p-0">
        重新发送验证{isShowSecond && `（${second}s）`}
      </MDBBtn>
    </div>
  );
};

export default EmailValidation;
