import { IDataAnalysisAccess } from 'app/shared/model/data-analysis-access.model';
import { ICohortAccess } from 'app/shared/model/cohort-access.model';
import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { OrganizationRole } from 'app/shared/model/enumerations/organization-role.model';

export interface IMembership {
  id?: number;
  role?: OrganizationRole;
  dataAnalysisAccesses?: IDataAnalysisAccess[];
  cohortAccesses?: ICohortAccess[];
  userProfile?: IUserProfile;
  organization?: IOrganization;
}

export const defaultValue: Readonly<IMembership> = {};
