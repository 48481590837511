import { IUser } from 'app/shared/model/user.model';

export interface IUserProfile {
  id?: number;
  username?: string;
  employer?: string;
  internalUser?: IUser;
}

export const defaultValue: Readonly<IUserProfile> = {};
