import { ICooperationCohort } from 'app/shared/model/cooperation-cohort.model';

export interface IAnalysisVariable {
  id?: number;
  name?: string;
  type?: string;
  description?: string;
  notes?: string;
  cooperationCohort?: ICooperationCohort;
}

export const defaultValue: Readonly<IAnalysisVariable> = {};
