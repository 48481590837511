import { IMembership } from 'app/shared/model/membership.model';
import { IRequestJoinOrg } from 'app/shared/model/request-join-org.model';
import { ICohort } from 'app/shared/model/cohort.model';
import { IDataAnalysis } from 'app/shared/model/data-analysis.model';

export interface IOrganization {
  id?: number;
  name?: string;
  memberships?: IMembership[];
  requestJoinOrgs?: IRequestJoinOrg[];
  cohorts?: ICohort[];
  dataAnalyses?: IDataAnalysis[];
}

export const defaultValue: Readonly<IOrganization> = {};
