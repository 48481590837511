import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IInvitationLink, defaultValue } from 'app/shared/model/invitation-link.model';

export const ACTION_TYPES = {
  FETCH_INVITATIONLINK_LIST: 'invitationLink/FETCH_INVITATIONLINK_LIST',
  FETCH_INVITATIONLINK: 'invitationLink/FETCH_INVITATIONLINK',
  CREATE_INVITATIONLINK: 'invitationLink/CREATE_INVITATIONLINK',
  UPDATE_INVITATIONLINK: 'invitationLink/UPDATE_INVITATIONLINK',
  DELETE_INVITATIONLINK: 'invitationLink/DELETE_INVITATIONLINK',
  RESET: 'invitationLink/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInvitationLink>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type InvitationLinkState = Readonly<typeof initialState>;

// Reducer

export default (state: InvitationLinkState = initialState, action): InvitationLinkState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVITATIONLINK_LIST):
    case REQUEST(ACTION_TYPES.FETCH_INVITATIONLINK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_INVITATIONLINK):
    case REQUEST(ACTION_TYPES.UPDATE_INVITATIONLINK):
    case REQUEST(ACTION_TYPES.DELETE_INVITATIONLINK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INVITATIONLINK_LIST):
    case FAILURE(ACTION_TYPES.FETCH_INVITATIONLINK):
    case FAILURE(ACTION_TYPES.CREATE_INVITATIONLINK):
    case FAILURE(ACTION_TYPES.UPDATE_INVITATIONLINK):
    case FAILURE(ACTION_TYPES.DELETE_INVITATIONLINK):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVITATIONLINK_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVITATIONLINK):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INVITATIONLINK):
    case SUCCESS(ACTION_TYPES.UPDATE_INVITATIONLINK):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_INVITATIONLINK):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invitation-links';
const apiExtendedUrl = '/api/v1/invitation-links';

// Actions

export const getEntities: ICrudGetAllAction<IInvitationLink> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_INVITATIONLINK_LIST,
  payload: axios.get<IInvitationLink>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IInvitationLink> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVITATIONLINK,
    payload: axios.get<IInvitationLink>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IInvitationLink> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVITATIONLINK,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const createExtendedEntity: ICrudPutAction<IInvitationLink> = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVITATIONLINK,
    payload: axios.post(apiExtendedUrl),
  });
  return result;
};

export const updateEntity: ICrudPutAction<IInvitationLink> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INVITATIONLINK,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IInvitationLink> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_INVITATIONLINK,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
