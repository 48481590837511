import { Moment } from 'moment';

export interface IBlock {
  id?: number;
  height?: number;
  hash?: string;
  timestamp?: string;
  tradingVolume?: number;
}

export const defaultValue: Readonly<IBlock> = {};
