import './login.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { checkIsExistEmail } from 'app/modules/account/register/register.reducer.ts';
import { MDBBtn, MDBInput } from 'mdbreact';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { extendedLogin } from 'app/shared/reducers/authentication';
import Loader from 'app/ddex/shared/model/loader.tsx';

export const Login = props => {
  const { history } = props;

  const url = new URL(window.location.href);

  const recaptchaRef = useRef(null);
  const formRef = useRef(null);

  const isExistEmail = useSelector((state: IRootState) => state.register.errorMessage);
  const loginError = useSelector((state: IRootState) => state.authentication.loginError);
  const loading = useSelector((state: IRootState) => state.authentication.loading);
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);

  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [form, setForm] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isErrorPassword, setIsErrorPassword] = useState(false);

  const dispatch = useDispatch();

  const changeFormClass = (key: string, value: string) => {
    const className = formRef.current.className;
    const isHaveClassName = className.includes(value);
    switch (key) {
      case 'add':
        !isHaveClassName && (formRef.current.className += value);
        break;
      case 'remove':
        isHaveClassName && (formRef.current.className = className.replace(value, ''));
        break;
      default:
        break;
    }
  };

  const changeHandler = event => {
    const name = event.target.name;
    setForm({ ...form, [name]: name !== 'rememberMe' ? event.target.value : event.target.checked });
    name !== 'rememberMe' && setIsErrorPassword(false);
  };

  const submitHandler = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  };

  const debouncedEmail = useCallback(
    debounce((email: any) => dispatch(checkIsExistEmail(email)), 500),
    []
  );

  const forgetPassword = () => {
    url.searchParams.has('ILKey')
      ? history.push(`/register/forget-password?ILKey=${url.searchParams.get('ILKey')}`)
      : history.push('/register/forget-password');
  };

  useEffect(() => {
    debouncedEmail(form.email);
  }, [form.email]);

  useEffect(() => {
    changeFormClass(isExistEmail === 1 ? 'remove' : 'add', ' notExistEmail');
    form.email === '' && changeFormClass('remove', ' notExistEmail');
  }, [isExistEmail, form.email]);

  useEffect(() => {
    changeFormClass('remove', ' errorPassword');
  }, [form.password, form.email]);

  useEffect(() => {
    const entity = {
      email: form.email,
      password: form.password,
      token: recaptchaToken,
      rememberMe: form.rememberMe,
      organizationKey: url.searchParams.has('ILKey') ? url.searchParams.get('ILKey') : '',
    };
    recaptchaToken !== '' && dispatch(extendedLogin(entity));
  }, [recaptchaToken]);

  useEffect(() => {
    const isFillAllInputs = Object.values(form).includes('');
    if (!isFillAllInputs && isExistEmail === 1 && !isErrorPassword) setIsDisabled(false);
    else setIsDisabled(true);
  }, [form, isExistEmail]);

  useEffect(() => {
    if (!loading && loginError) {
      changeFormClass('add', ' errorPassword');
      setIsDisabled(true);
      setIsErrorPassword(true);
    }
  }, [loginError, loading]);

  useEffect(() => {
    isAuthenticated && history.push('/app');
  }, [isAuthenticated]);

  return (
    <div className="login">
      <form className="needs-validation" onSubmit={submitHandler} autoComplete="off" noValidate ref={formRef}>
        <p className="h3">登录</p>
        <div className="inputGroup">
          <MDBInput icon="times" label="邮箱" group type="email" onChange={changeHandler} name="email" value={form.email}>
            <div className="invalid-feedback">该邮箱未注册</div>
          </MDBInput>

          <MDBInput icon="times" label="密码" group type="password" value={form.password} onChange={changeHandler} name="password">
            <div className="invalid-feedback">密码错误</div>
          </MDBInput>

          <div>
            <div className="custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" className="custom-control-input" id="defaultInline1" name="rememberMe" onChange={changeHandler} />
              <label className="custom-control-label" htmlFor="defaultInline1">
                记住我
              </label>
            </div>
            <div onClick={forgetPassword} className="forgetPassword">
              忘记密码?
            </div>
          </div>
        </div>
        <div className="text-right">
          <MDBBtn color="cyan" type="submit" disabled={isDisabled}>
            {loading ? <Loader></Loader> : '登录'}
          </MDBBtn>
        </div>
        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.RECAPTCHA_TOKEN} size="invisible" onChange={setRecaptchaToken} />
      </form>
    </div>
  );
};

export default Login;
