// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../content/images/login_register.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".account {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.account .col-8 {\n  margin-left: 12.4479167%;\n  padding: 0;\n  display: flex;\n  justify-content: space-between;\n}\n.account__header {\n  height: 9.75rem;\n  min-height: 9.75rem;\n  border-bottom: 1px solid #a20000;\n  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);\n}\n.account__header > .col-8 > .logo {\n  padding-top: 3.75rem;\n  width: 16.625rem;\n}\n.account__header > .col-8 > .logo > img {\n  width: 100%;\n}\n.account__header > .col-8 > .nav {\n  margin: 4.625rem 0 2.5rem;\n  height: 2.5625rem;\n}\n.account__header > .col-8 > .nav > .select {\n  position: relative;\n}\n.account__header > .col-8 > .nav > .select > .fa {\n  position: absolute;\n  color: #a20000;\n  line-height: 2.5625rem;\n}\n.account__header > .col-8 > .nav > .select > .fa-globe-asia {\n  left: 0.5625rem;\n  top: 0;\n  font-size: 1.25rem;\n}\n.account__header > .col-8 > .nav > .select > .fa-chevron-down {\n  top: 0;\n  right: 0;\n  font-size: 1rem;\n}\n.account__header > .col-8 > .nav > .switchLoginRegister {\n  margin-left: 3.8125rem;\n  border: 2px solid #a20000;\n  border-radius: 0.3125rem;\n  color: #a20000;\n  width: 5.9375rem;\n  font-size: 1.125rem;\n  background: transparent;\n  outline: none;\n}\n.account__main {\n  flex-grow: 1;\n}\n.account__main > .col {\n  padding: 0 20.5729167% 0 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.account__main > .col > .img {\n  width: 58.4918033%;\n  height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n}\n.account__main > .col > .view-routes {\n  width: 33.3770492%;\n}", ""]);
// Exports
module.exports = exports;
