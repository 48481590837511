import './register.scss';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsExistEmail, extendedRegister } from 'app/modules/account/register/register.reducer';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import Loader from 'app/ddex/shared/model/loader';
import { debounce } from 'lodash';
import { Storage } from 'react-jhipster';
import { getUserInfo } from 'app/entities/user-profile/user-profile.reducer';
import { requestJoinOrg } from 'app/entities/request-join-org/request-join-org.reducer';

export type IRegisterProps = RouteComponentProps;

export const Register = (props: IRegisterProps) => {
  const { history } = props;

  const recaptchaRef = useRef(null);
  const formRef = useRef(null);

  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    employer: '',
  });
  const [passwordValidation, setPasswordValidation] = useState({
    characterValidation: false,
    letterValidation: false,
    numberValidation: false,
    specialSymbolValidation: false,
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isInvalidPassword, setIsInvalidPassword] = useState(true);
  const [isChangePasswordIcon, setIsChangePasswordIcon] = useState(false);
  const [isConsistentPassword, setIsConsistentPassword] = useState(null);

  const userInfo = useSelector((state: IRootState) => state.userProfile.entity);
  const isExistEmail = useSelector((state: IRootState) => state.register.errorMessage);
  const registrationSuccess = useSelector((state: IRootState) => state.register.registrationSuccess);
  const loading = useSelector((state: IRootState) => state.register.loading);

  const dispatch = useDispatch();

  const submitHandler = event => {
    event.preventDefault();
    recaptchaRef.current.execute();
  };

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const changeFormClass = (key, value) => {
    const className = formRef.current.className;
    const isHaveClassName = className.includes(value);
    switch (key) {
      case 'add':
        !isHaveClassName && (formRef.current.className += value);
        break;
      case 'remove':
        isHaveClassName && (formRef.current.className = className.replace(value, ''));
        break;
      default:
        break;
    }
  };

  const debouncedSave = useCallback(
    debounce((email: string) => dispatch(checkIsExistEmail(email)), 500),
    []
  );

  const checkEmail = email => {
    if (email !== '') {
      const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
      const emailValidation = EMAIL_REGEXP.test(email);
      changeFormClass(emailValidation ? 'remove' : 'add', ' invalidEmail');
      !emailValidation && changeFormClass('remove', ' existEmail');
      debouncedSave(email);
    } else {
      changeFormClass('remove', ' invalidEmail');
    }
  };

  const checkPassword = password => {
    const characterValidation = password.length >= 8;
    const letterValidation = /(?=.*[a-z])(?=.*[A-Z])/.test(password);
    const numberValidation = /\d/.test(password);
    const specialSymbolPattern = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
    const specialSymbolValidation = specialSymbolPattern.test(password);
    changeFormClass(characterValidation ? 'add' : 'remove', ' characterValidation');
    changeFormClass(letterValidation ? 'add' : 'remove', ' letterValidation');
    changeFormClass(numberValidation ? 'add' : 'remove', ' numberValidation');
    changeFormClass(specialSymbolValidation ? 'add' : 'remove', ' specialSymbolValidation');

    setPasswordValidation({ ...passwordValidation, characterValidation, letterValidation, numberValidation, specialSymbolValidation });
  };

  const checkConfirmPassword = (password, confirmPassword) => {
    setIsConsistentPassword(confirmPassword === password);
    if (confirmPassword === password) {
      confirmPassword !== '' && changeFormClass('add', ' consistentPassword');
      changeFormClass('remove', ' inconsistentPassword');
    } else {
      changeFormClass('remove', ' consistentPassword');
      confirmPassword === '' ? changeFormClass('remove', ' inconsistentPassword') : changeFormClass('add', ' inconsistentPassword');
    }
  };

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    if (userInfo.id && token) {
      const url = new URL(window.location.href);
      url.searchParams.has('ILKey') && dispatch(requestJoinOrg(url.searchParams.get('ILKey'), userInfo.id));
      history.push('/app');
    }
  }, [userInfo]);

  useEffect(() => {
    (!isInvalidPassword || form.password === '') && changeFormClass('remove', ' passwordInvalid');
    changeFormClass(isExistEmail ? 'add' : 'remove', ' existEmail');

    const isFillAllInputs = Object.values(form).includes('');
    const isValidEmail = formRef.current.className.includes('invalidEmail');
    if (!isFillAllInputs && isExistEmail === 0 && !isValidEmail && !isInvalidPassword && form.confirmPassword === form.password)
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [form, isInvalidPassword, isExistEmail]);

  useEffect(() => {
    checkEmail(form.email);
  }, [form.email]);

  useEffect(() => {
    checkPassword(form.password);
    checkConfirmPassword(form.password, form.confirmPassword);
  }, [form.password, form.confirmPassword]);

  useEffect(() => {
    setIsInvalidPassword(Object.values(passwordValidation).includes(false));
  }, [passwordValidation]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const entity = {
      username: form.name,
      login: form.email,
      email: form.email,
      password: form.password,
      employer: form.employer,
      token: recaptchaToken,
      organizationKey: url.searchParams.has('ILKey') ? url.searchParams.get('ILKey') : '',
    };
    recaptchaToken !== '' && dispatch(extendedRegister(entity));
  }, [recaptchaToken]);

  useEffect(() => {
    const path = {
      pathname: 'register/email-validation',
      state: { email: form.email },
    };
    registrationSuccess && history.push(path);
  }, [registrationSuccess]);

  return (
    <div className="register">
      <form className="needs-validation" onSubmit={submitHandler} autoComplete="off" noValidate ref={formRef}>
        <p className="h3">注册</p>
        <div className="inputGroup">
          <MDBInput label="姓名" group type="text" value={form.name} onChange={changeHandler} name="name"></MDBInput>

          <MDBInput icon="times" label="邮箱" group type="email" onChange={changeHandler} name="email" value={form.email}>
            <div className="invalid-feedback">该邮箱已注册, 请直接登录</div>
            <div className="invalid-feedback">邮箱无效</div>
          </MDBInput>

          <MDBInput
            icon={isConsistentPassword ? 'check' : 'times'}
            label="密码"
            group
            type="password"
            value={form.password}
            onChange={changeHandler}
            name="password"
            onBlur={event => {
              const target = event.target as HTMLInputElement;
              if (target.value !== '') {
                setIsChangePasswordIcon(true);
                isInvalidPassword && changeFormClass('add', ' passwordInvalid');
              }
            }}
          >
            <div className="passwordValidation">
              <div className="character">
                <MDBIcon icon={isChangePasswordIcon && !passwordValidation.characterValidation ? 'times-circle' : 'check-circle'} />
                包含8位及以上字符
              </div>
              <div className="letter">
                <MDBIcon icon={isChangePasswordIcon && !passwordValidation.letterValidation ? 'times-circle' : 'check-circle'} />
                包含大小写字母
              </div>
              <div className="number">
                <MDBIcon icon={isChangePasswordIcon && !passwordValidation.numberValidation ? 'times-circle' : 'check-circle'} />
                包含数字
              </div>
              <div className="specialSymbol">
                <MDBIcon icon={isChangePasswordIcon && !passwordValidation.specialSymbolValidation ? 'times-circle' : 'check-circle'} />
                包含特殊符号
              </div>
            </div>
          </MDBInput>

          <MDBInput
            icon={isConsistentPassword ? 'check' : 'times'}
            label="确认密码"
            group
            type="password"
            value={form.confirmPassword}
            onChange={changeHandler}
            name="confirmPassword"
          >
            <div className="invalid-feedback">密码不一致</div>
          </MDBInput>

          <MDBInput label="工作单位" group type="text" value={form.employer} onChange={changeHandler} name="employer"></MDBInput>
        </div>
        <div className="text-right">
          <MDBBtn color="cyan" type="submit" disabled={isDisabled}>
            {loading && recaptchaToken !== '' ? <Loader></Loader> : '注册'}
          </MDBBtn>
        </div>
        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.RECAPTCHA_TOKEN} size="invisible" onChange={setRecaptchaToken} />
      </form>
    </div>
  );
};

export default Register;
