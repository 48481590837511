import { ICohort } from 'app/shared/model/cohort.model';

export interface ICohortDictRecord {
  id?: number;
  name?: string;
  type?: string;
  description?: string;
  notes?: any;
  cohort?: ICohort;
}

export const defaultValue: Readonly<ICohortDictRecord> = {};
