import './DDExRouter.scss';

import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React, { lazy } from 'react';
import PageNotFound from 'app/shared/error/page-not-found';
import Account from 'app/ddex/modules/account.tsx';

const DDExApp = lazy(() => import('app/ddex/DDExApp'));

const DDExRouter = () => {
  return (
    <Switch>
      <ErrorBoundaryRoute path="/app" component={DDExApp} />
      <ErrorBoundaryRoute path="/" component={Account} />
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  );
};

export default DDExRouter;
