import './account.scss';
import React, { useState } from 'react';
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { Storage } from 'react-jhipster';
import { setLocale } from 'app/shared/reducers/locale';
import { useDispatch } from 'react-redux';
import AppRoutes from './routes';

export type IAccountProps = RouteComponentProps;

export const Account = (props: IAccountProps) => {
  const { location, history } = props;

  const isRegister = location.pathname.includes('register');

  const languageOptions = [
    { value: 'zh-cn', label: '简体中文' },
    { value: 'en', label: 'English' },
  ];
  const languageStyles = {
    container: styles => ({ ...styles, width: '8.875rem', height: '100%' }),
    indicatorsContainer: styles => ({ ...styles, display: 'none' }),
    control: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      minHeight: '0',
      height: '100%',
      ':hover': {
        ...styles[';hover'],
        border: 'none',
      },
    }),
    valueContainer: styles => ({ ...styles, padding: '0', height: '100%' }),
    singleValue: styles => ({
      ...styles,
      margin: '0 0 0 2.5625rem',
      fontSize: '1.125rem',
      fontWeight: '400',
      lineHeight: '1.5625rem',
      color: '#a20000',
    }),
    menu: styles => ({
      ...styles,
      margin: '0.75rem 0 0',
      boxShadow: 'none',
    }),
    menuList: styles => ({ ...styles, padding: '0', border: '1px solid #a20000', borderRadius: '0.3125rem' }),
    option: (styles, { data, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? '#a20000' : '#FEFCFB',
      color: isSelected ? '#fff' : '#616161',
      fontSize: '1.125rem',
      lineHeight: '1.5625rem',
      padding: '0.5625rem 0 0.625rem 2.5rem',
      ':active': {
        ...styles[';active'],
        backgroundColor: '#a20000',
      },
      ':hover': {
        ...styles[';hover'],
        backgroundColor: '#a20000',
        color: '#fff',
      },
    }),
  };

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const dispatch = useDispatch();

  const handleLocaleChange = option => {
    const langKey = option.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const switchLoginRegister = () => {
    const url = new URL(window.location.href);
    if (isRegister) {
      url.searchParams.has('ILKey') ? history.push(`/?ILKey=${url.searchParams.get('ILKey')}`) : history.push('/');
    } else {
      url.searchParams.has('ILKey') ? history.push(`/register?ILKey=${url.searchParams.get('ILKey')}`) : history.push('/register');
    }
  };

  return (
    <MDBContainer className="account" fluid>
      <MDBRow className="account__header">
        <MDBCol size="8">
          <div className="logo">
            <img src="../../../content/images/logo_dark.png" alt="" />
          </div>
          <div className="nav">
            <div className="select">
              <Select
                defaultValue={languageOptions[0]}
                name="color"
                options={languageOptions}
                styles={languageStyles}
                isSearchable={false}
                onMenuOpen={() => setIsOpenMenu(true)}
                onMenuClose={() => setIsOpenMenu(false)}
                onChange={handleLocaleChange}
              />
              <MDBIcon icon="globe-asia" />
              <MDBIcon icon="chevron-down" className={isOpenMenu ? 'chevronDownUp chevronUpDown' : 'chevronUpDown'} />
            </div>
            <button onClick={switchLoginRegister} className="switchLoginRegister">
              {isRegister ? '登录' : '注册'}
            </button>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="account__main">
        <MDBCol className="">
          <div className="img"></div>
          <AppRoutes></AppRoutes>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Account;
