import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDataAnalysisAccess, defaultValue } from 'app/shared/model/data-analysis-access.model';

export const ACTION_TYPES = {
  SEARCH_DATAANALYSISACCESSES: 'dataAnalysisAccess/SEARCH_DATAANALYSISACCESSES',
  FETCH_DATAANALYSISACCESS_LIST: 'dataAnalysisAccess/FETCH_DATAANALYSISACCESS_LIST',
  FETCH_DATAANALYSISACCESS: 'dataAnalysisAccess/FETCH_DATAANALYSISACCESS',
  CREATE_DATAANALYSISACCESS: 'dataAnalysisAccess/CREATE_DATAANALYSISACCESS',
  UPDATE_DATAANALYSISACCESS: 'dataAnalysisAccess/UPDATE_DATAANALYSISACCESS',
  DELETE_DATAANALYSISACCESS: 'dataAnalysisAccess/DELETE_DATAANALYSISACCESS',
  RESET: 'dataAnalysisAccess/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDataAnalysisAccess>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type DataAnalysisAccessState = Readonly<typeof initialState>;

// Reducer

export default (state: DataAnalysisAccessState = initialState, action): DataAnalysisAccessState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_DATAANALYSISACCESSES):
    case REQUEST(ACTION_TYPES.FETCH_DATAANALYSISACCESS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DATAANALYSISACCESS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_DATAANALYSISACCESS):
    case REQUEST(ACTION_TYPES.UPDATE_DATAANALYSISACCESS):
    case REQUEST(ACTION_TYPES.DELETE_DATAANALYSISACCESS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_DATAANALYSISACCESSES):
    case FAILURE(ACTION_TYPES.FETCH_DATAANALYSISACCESS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DATAANALYSISACCESS):
    case FAILURE(ACTION_TYPES.CREATE_DATAANALYSISACCESS):
    case FAILURE(ACTION_TYPES.UPDATE_DATAANALYSISACCESS):
    case FAILURE(ACTION_TYPES.DELETE_DATAANALYSISACCESS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_DATAANALYSISACCESSES):
    case SUCCESS(ACTION_TYPES.FETCH_DATAANALYSISACCESS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DATAANALYSISACCESS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DATAANALYSISACCESS):
    case SUCCESS(ACTION_TYPES.UPDATE_DATAANALYSISACCESS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DATAANALYSISACCESS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/data-analysis-accesses';
const apiSearchUrl = 'api/_search/data-analysis-accesses';

// Actions

export const getSearchEntities: ICrudSearchAction<IDataAnalysisAccess> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_DATAANALYSISACCESSES,
  payload: axios.get<IDataAnalysisAccess>(`${apiSearchUrl}?query=${query}`),
});

export const getEntities: ICrudGetAllAction<IDataAnalysisAccess> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DATAANALYSISACCESS_LIST,
  payload: axios.get<IDataAnalysisAccess>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IDataAnalysisAccess> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DATAANALYSISACCESS,
    payload: axios.get<IDataAnalysisAccess>(requestUrl),
  };
};

export const getUserRole: ICrudGetAction<IDataAnalysisAccess> = id => {
  const requestUrl = `api/v1/current-user/data-analysis/${id}/role`;
  return {
    type: ACTION_TYPES.FETCH_DATAANALYSISACCESS,
    payload: axios.get<IDataAnalysisAccess>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IDataAnalysisAccess> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DATAANALYSISACCESS,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDataAnalysisAccess> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DATAANALYSISACCESS,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDataAnalysisAccess> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DATAANALYSISACCESS,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
