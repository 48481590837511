import {BrowserRouter as Router, Switch} from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React, {lazy, Suspense, useEffect} from 'react';
import DDExRouter from "app/ddex/DDExRouter";
import {AUTHORITIES} from "app/config/constants";
import PrivateRoute from 'app/shared/auth/private-route';
import {Storage} from 'react-jhipster';
import {useDispatch} from 'react-redux';
import {getSession} from "app/shared/reducers/authentication";


const JhipsterAppComponent = lazy(() => import('./app'));

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

const AppRouter = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    if(token){
      dispatch(getSession())
    }

  }, []);

  const loading = <div className="app-loading">
    <div className="lds-css ng-scope">
      <div className="lds-pacman">
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>;
  return (

    <Router basename={baseHref}>
      <Suspense fallback={loading}>
        <Switch>
          {process.env.NODE_ENV === 'development' ?
            <ErrorBoundaryRoute path="/test" component={JhipsterAppComponent}/>
            : <PrivateRoute path="/test" component={JhipsterAppComponent} hasAnyAuthorities={[AUTHORITIES.ADMIN]}/>
          }
          <ErrorBoundaryRoute path="/" component={DDExRouter}/>
        </Switch>
      </Suspense>
    </Router>
  )
}

export default AppRouter;
