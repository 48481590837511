import React from 'react';
import { handlePasswordInitialize } from 'app/modules/account/password-reset/password-reset.reducer.ts';
import ResetPassword from 'app/ddex/modules/reset-password';

export const InitializePassword = props => {
  const { location, history } = props;
  return (
    <ResetPassword title="设置初始密码" location={location} history={history} resetPassword={handlePasswordInitialize}></ResetPassword>
  );
};

export default InitializePassword;
