import './forget-password.scss';
import React, { useEffect, useRef, useState } from 'react';
import { MDBBtn, MDBInput } from 'mdbreact';
import Loader from 'app/ddex/shared/model/loader.tsx';
import { IRootState } from 'app/shared/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { forgetPassword } from 'app/modules/account/password-reset/password-reset.reducer.ts';

export const ForgetPassword = props => {
  const loading = useSelector((state: IRootState) => state.passwordReset.loading);

  const formRef = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [initSecond] = useState(60);
  const [second, setSecond] = useState(initSecond);
  const [isShowSecond, setIShowSecond] = useState(false);

  const dispatch = useDispatch();

  const changeHandler = event => {
    setEmail(event.target.value);
  };

  const changeFormClass = (key, value) => {
    const className = formRef.current.className;
    const isHaveClassName = className.includes(value);
    switch (key) {
      case 'add':
        !isHaveClassName && (formRef.current.className += value);
        break;
      case 'remove':
        isHaveClassName && (formRef.current.className = className.replace(value, ''));
        break;
      default:
        break;
    }
  };

  const checkEmail = () => {
    if (email !== '') {
      const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
      return EMAIL_REGEXP.test(email);
    }
  };

  const countdown = () => {
    setIsDisabled(true);
    setIShowSecond(true);
    for (let i = 1; i <= initSecond; ++i) {
      setTimeout(() => {
        setSecond(initSecond - i);
        if (i === initSecond) {
          setIsDisabled(false);
          setIShowSecond(false);
          setSecond(initSecond);
        }
      }, i * 1000);
    }
  };

  const submitHandler = event => {
    event.preventDefault();
    const emailValidation = checkEmail();
    if (emailValidation) {
      dispatch(forgetPassword(email));
      countdown();
    } else changeFormClass('add', ' invalidEmail');
  };

  useEffect(() => {
    setIsDisabled(email === '');
    changeFormClass('remove', ' invalidEmail');
  }, [email]);

  return (
    <div className="forgetPassword">
      <h3>忘记密码？</h3>
      <p>请输入您的邮箱以重置密码。如果您没有收到邮件，请检查您的垃圾邮件文件箱。</p>
      <form className="needs-validation" onSubmit={submitHandler} autoComplete="off" noValidate ref={formRef}>
        <div className="inputGroup">
          <MDBInput icon="times" label="邮箱" group type="email" onChange={changeHandler} name="email" value={email}>
            <div className="invalid-feedback">邮箱无效</div>
          </MDBInput>
        </div>
        <div className="text-right">
          <MDBBtn type="submit" disabled={isDisabled}>
            {loading ? <Loader></Loader> : isShowSecond ? `提交（${second}s）` : `提交`}
          </MDBBtn>
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
