import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDataAnalysis, defaultValue } from 'app/shared/model/data-analysis.model';
import { ICohortDictRecord } from 'app/shared/model/cohort-dict-record.model';

export const ACTION_TYPES = {
  SEARCH_DATAANALYSES: 'dataAnalysis/SEARCH_DATAANALYSES',
  FETCH_DATAANALYSIS_LIST: 'dataAnalysis/FETCH_DATAANALYSIS_LIST',
  FETCH_DATAANALYSIS: 'dataAnalysis/FETCH_DATAANALYSIS',
  CREATE_DATAANALYSIS: 'dataAnalysis/CREATE_DATAANALYSIS',
  UPDATE_DATAANALYSIS: 'dataAnalysis/UPDATE_DATAANALYSIS',
  DELETE_DATAANALYSIS: 'dataAnalysis/DELETE_DATAANALYSIS',
  SELECT_DICT_RECORD: 'dataAnalysis/SELECT_DICT_RECORD',
  SET_BLOB: 'dataAnalysis/SET_BLOB',
  RESET: 'dataAnalysis/RESET',
  DESELECT_DICT_RECORD: 'dataAnalysis/DESELECT_DICT_RECORD',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDataAnalysis>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteSuccess: false,
  selectedDictRecords: [] as ReadonlyArray<ICohortDictRecord>,
};

export type DataAnalysisState = Readonly<typeof initialState>;

// Reducer

export default (state: DataAnalysisState = initialState, action): DataAnalysisState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_DATAANALYSES):
    case REQUEST(ACTION_TYPES.FETCH_DATAANALYSIS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DATAANALYSIS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_DATAANALYSIS):
    case REQUEST(ACTION_TYPES.UPDATE_DATAANALYSIS):
    case REQUEST(ACTION_TYPES.DELETE_DATAANALYSIS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        deleteSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_DATAANALYSES):
    case FAILURE(ACTION_TYPES.FETCH_DATAANALYSIS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DATAANALYSIS):
    case FAILURE(ACTION_TYPES.CREATE_DATAANALYSIS):
    case FAILURE(ACTION_TYPES.UPDATE_DATAANALYSIS):
    case FAILURE(ACTION_TYPES.DELETE_DATAANALYSIS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        deleteSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_DATAANALYSES):
    case SUCCESS(ACTION_TYPES.FETCH_DATAANALYSIS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_DATAANALYSIS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DATAANALYSIS):
    case SUCCESS(ACTION_TYPES.UPDATE_DATAANALYSIS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DATAANALYSIS):
      return {
        ...state,
        updating: false,
        deleteSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
        },
      };
    }
    case ACTION_TYPES.SELECT_DICT_RECORD: {
      return {
        ...state,
        selectedDictRecords: [...state.selectedDictRecords, action.payload],
      };
    }
    case ACTION_TYPES.DESELECT_DICT_RECORD: {
      return {
        ...state,
        selectedDictRecords: [...state.selectedDictRecords.filter(i => i !== action.payload)],
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/data-analyses';
const apiSearchUrl = 'api/_search/data-analyses';
const apiExtendedUrl = 'api/v1/data-analysis';
const apiExtendedSearchUrl = 'api/v1/data-analysis/_search';

// Actions

export const getSearchEntities: ICrudSearchAction<IDataAnalysis> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_DATAANALYSES,
  payload: axios.get<IDataAnalysis>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getExtendedSearchEntities: ICrudSearchAction<IDataAnalysis> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_DATAANALYSES,
  payload: axios.get<IDataAnalysis>(`${apiExtendedSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IDataAnalysis> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_DATAANALYSIS_LIST,
    payload: axios.get<IDataAnalysis>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getExtendedEntities: ICrudGetAllAction<IDataAnalysis> = (page, size, sort) => {
  const requestUrl = `api/v1/current-user/data-analyses${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_DATAANALYSIS_LIST,
    payload: axios.get<IDataAnalysis>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getNoSendApplicationProjects = id => {
  const requestUrl = `api/v1/current-user/manage/data-analyses?cohortId=${id}`;
  return {
    type: ACTION_TYPES.FETCH_DATAANALYSIS_LIST,
    payload: axios.get<IDataAnalysis>(requestUrl),
  };
};

export const getEntity: ICrudGetAction<IDataAnalysis> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DATAANALYSIS,
    payload: axios.get<IDataAnalysis>(requestUrl),
  };
};

export const getExtendedEntity: ICrudGetAction<IDataAnalysis> = id => {
  const requestUrl = `${apiExtendedUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DATAANALYSIS,
    payload: axios.get<IDataAnalysis>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IDataAnalysis> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DATAANALYSIS,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const createExtendedEntity: ICrudPutAction<IDataAnalysis> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DATAANALYSIS,
    payload: axios.post(`${apiExtendedUrl}`, cleanEntity(entity)),
  });
  return result;
};

export const updateEntity: ICrudPutAction<IDataAnalysis> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DATAANALYSIS,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateProject: ICrudPutAction<IDataAnalysis> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DATAANALYSIS,
    payload: axios.put(`${apiExtendedUrl}`, cleanEntity(entity)),
  });
  dispatch(getExtendedEntity(entity.id));
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDataAnalysis> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DATAANALYSIS,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const deleteProject: ICrudDeleteAction<IDataAnalysis> = id => async dispatch => {
  const requestUrl = `${apiExtendedUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DATAANALYSIS,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const selectDictRecord = record => ({
  type: ACTION_TYPES.SELECT_DICT_RECORD,
  payload: record,
});

export const deselectDictRecord = record => ({
  type: ACTION_TYPES.DESELECT_DICT_RECORD,
  payload: record,
});
