import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICooperationCohort, defaultValue } from 'app/shared/model/cooperation-cohort.model';

export const ACTION_TYPES = {
  SEARCH_COOPERATIONCOHORTS: 'cooperationCohort/SEARCH_COOPERATIONCOHORTS',
  FETCH_COOPERATIONCOHORT_LIST: 'cooperationCohort/FETCH_COOPERATIONCOHORT_LIST',
  FETCH_COOPERATIONCOHORT: 'cooperationCohort/FETCH_COOPERATIONCOHORT',
  CREATE_COOPERATIONCOHORT: 'cooperationCohort/CREATE_COOPERATIONCOHORT',
  UPDATE_COOPERATIONCOHORT: 'cooperationCohort/UPDATE_COOPERATIONCOHORT',
  DELETE_COOPERATIONCOHORT: 'cooperationCohort/DELETE_COOPERATIONCOHORT',
  RESET: 'cooperationCohort/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICooperationCohort>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  createSuccess: false,
};

export type CooperationCohortState = Readonly<typeof initialState>;

// Reducer

export default (state: CooperationCohortState = initialState, action): CooperationCohortState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_COOPERATIONCOHORTS):
    case REQUEST(ACTION_TYPES.FETCH_COOPERATIONCOHORT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_COOPERATIONCOHORT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_COOPERATIONCOHORT):
    case REQUEST(ACTION_TYPES.UPDATE_COOPERATIONCOHORT):
    case REQUEST(ACTION_TYPES.DELETE_COOPERATIONCOHORT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        createSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_COOPERATIONCOHORTS):
    case FAILURE(ACTION_TYPES.FETCH_COOPERATIONCOHORT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_COOPERATIONCOHORT):
    case FAILURE(ACTION_TYPES.CREATE_COOPERATIONCOHORT):
    case FAILURE(ACTION_TYPES.UPDATE_COOPERATIONCOHORT):
    case FAILURE(ACTION_TYPES.DELETE_COOPERATIONCOHORT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        createSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_COOPERATIONCOHORTS):
    case SUCCESS(ACTION_TYPES.FETCH_COOPERATIONCOHORT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_COOPERATIONCOHORT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_COOPERATIONCOHORT):
      return {
        ...state,
        updating: false,
        createSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_COOPERATIONCOHORT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_COOPERATIONCOHORT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/cooperation-cohorts';
const apiSearchUrl = 'api/_search/cooperation-cohorts';

// Actions

export const getSearchEntities: ICrudSearchAction<ICooperationCohort> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_COOPERATIONCOHORTS,
  payload: axios.get<ICooperationCohort>(`${apiSearchUrl}?query=${query}`),
});

export const getEntities: ICrudGetAllAction<ICooperationCohort> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_COOPERATIONCOHORT_LIST,
  payload: axios.get<ICooperationCohort>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getExtendedEntities: ICrudGetAction<ICooperationCohort> = id => {
  const requestUrl = `api/v1/cooperation-cohorts/${id}`;
  return {
    type: ACTION_TYPES.FETCH_COOPERATIONCOHORT_LIST,
    payload: axios.get<ICooperationCohort>(requestUrl),
  };
};

export const getEntity: ICrudGetAction<ICooperationCohort> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_COOPERATIONCOHORT,
    payload: axios.get<ICooperationCohort>(requestUrl),
  };
};

export const getCooperationCohort = (cohortId, ProjectId) => {
  const requestUrl = `api/v1/cooperation/${ProjectId}?cohortId=${cohortId}`;
  return {
    type: ACTION_TYPES.FETCH_COOPERATIONCOHORT,
    payload: axios.get<ICooperationCohort>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ICooperationCohort> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_COOPERATIONCOHORT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const createExtendedEntity = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_COOPERATIONCOHORT,
    payload: axios.post('api/v1/cooperation-cohorts', cleanEntity(entity)),
  });
  return result;
};

export const updateEntity: ICrudPutAction<ICooperationCohort> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_COOPERATIONCOHORT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateCooperationCohortStatus = (id, status) => async dispatch => {
  const requestUrl = `api/v1/cooperation-cohort/${id}?status=${status}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_COOPERATIONCOHORT,
    payload: axios.put(requestUrl),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICooperationCohort> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_COOPERATIONCOHORT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
