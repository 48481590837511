import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import cohort, {
  CohortState
} from 'app/entities/cohort/cohort.reducer';
// prettier-ignore
import dictUploadHistory, {
  DictUploadHistoryState
} from 'app/entities/dict-upload-history/dict-upload-history.reducer';
// prettier-ignore
import cohortDictRecord, {
  CohortDictRecordState
} from 'app/entities/cohort-dict-record/cohort-dict-record.reducer';
// prettier-ignore
import dataAnalysis, {
  DataAnalysisState
} from 'app/entities/data-analysis/data-analysis.reducer';
// prettier-ignore
import cohortType, {
  CohortTypeState
} from 'app/entities/cohort-type/cohort-type.reducer';
// prettier-ignore
import questionnaireType, {
  QuestionnaireTypeState
} from 'app/entities/questionnaire-type/questionnaire-type.reducer';
// prettier-ignore
import userProfile, {
  UserProfileState
} from 'app/entities/user-profile/user-profile.reducer';
// prettier-ignore
import block, {
  BlockState
} from 'app/entities/block/block.reducer';
// prettier-ignore
import transaction, {
  TransactionState
} from 'app/entities/transaction/transaction.reducer';
// prettier-ignore
import analysisVariable, {
  AnalysisVariableState
} from 'app/entities/analysis-variable/analysis-variable.reducer';
// prettier-ignore
import cooperationCohort, {
  CooperationCohortState
} from 'app/entities/cooperation-cohort/cooperation-cohort.reducer';
// prettier-ignore
import organization, {
  OrganizationState
} from 'app/entities/organization/organization.reducer';
// prettier-ignore
import membership, {
  MembershipState
} from 'app/entities/membership/membership.reducer';
// prettier-ignore
import invitationLink, {
  InvitationLinkState
} from 'app/entities/invitation-link/invitation-link.reducer';
// prettier-ignore
import requestJoinOrg, {
  RequestJoinOrgState
} from 'app/entities/request-join-org/request-join-org.reducer';
// prettier-ignore
import cohortAccess, {
  CohortAccessState
} from 'app/entities/cohort-access/cohort-access.reducer';
// prettier-ignore
import dataAnalysisAccess, {
  DataAnalysisAccessState
} from 'app/entities/data-analysis-access/data-analysis-access.reducer';
// prettier-ignore
import workGroup, {
  WorkGroupState
} from 'app/entities/work-group/work-group.reducer';
// prettier-ignore
import application, {
  ApplicationState
} from 'app/entities/application/application.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly cohort: CohortState;
  readonly dictUploadHistory: DictUploadHistoryState;
  readonly cohortDictRecord: CohortDictRecordState;
  readonly dataAnalysis: DataAnalysisState;
  readonly cohortType: CohortTypeState;
  readonly questionnaireType: QuestionnaireTypeState;
  readonly userProfile: UserProfileState;
  readonly block: BlockState;
  readonly transaction: TransactionState;
  readonly analysisVariable: AnalysisVariableState;
  readonly cooperationCohort: CooperationCohortState;
  readonly organization: OrganizationState;
  readonly membership: MembershipState;
  readonly invitationLink: InvitationLinkState;
  readonly requestJoinOrg: RequestJoinOrgState;
  readonly cohortAccess: CohortAccessState;
  readonly dataAnalysisAccess: DataAnalysisAccessState;
  readonly workGroup: WorkGroupState;
  readonly application: ApplicationState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  cohort,
  dictUploadHistory,
  cohortDictRecord,
  dataAnalysis,
  cohortType,
  questionnaireType,
  userProfile,
  block,
  transaction,
  analysisVariable,
  cooperationCohort,
  organization,
  membership,
  invitationLink,
  requestJoinOrg,
  cohortAccess,
  dataAnalysisAccess,
  workGroup,
  application,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
});

export default rootReducer;
