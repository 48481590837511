import React from 'react';

const Loader = props => {
  const { height } = props;
  return (
    <>
      <div className="d-flex justify-content-center align-items-center" style={height ? { height } : null}>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Loader;
