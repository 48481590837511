import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import Register from 'app/ddex/modules/register/register.tsx';
import EmailValidation from 'app/ddex/modules/register/email-validation.tsx';
import RegisterSuccess from 'app/ddex/modules/register/register-success.tsx';
import InitializePassword from 'app/ddex/modules/register/initialize-password.tsx';
import Login from 'app/ddex/modules/login/login';
import ForgetPassword from 'app/ddex/modules/login/forget-password';
import ResetPassword from 'app/ddex/modules/login/reset-password';
import PageNotFound from 'app/shared/error/page-not-found';

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <div className="view-routes">
      <Switch>
        <ErrorBoundaryRoute path={`/register/reset-password`} component={ResetPassword} />
        <ErrorBoundaryRoute path={`/register/forget-password`} component={ForgetPassword} />
        <ErrorBoundaryRoute path={`/register/initialize-password`} component={InitializePassword} />
        <ErrorBoundaryRoute path={`/register/register-success`} component={RegisterSuccess} />
        <ErrorBoundaryRoute path={`/register/email-validation`} component={EmailValidation} />
        <ErrorBoundaryRoute path={`/register`} component={Register} />
        <ErrorBoundaryRoute path={`/`} component={Login} />
        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
