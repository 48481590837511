import { ICohort } from 'app/shared/model/cohort.model';
import { IMembership } from 'app/shared/model/membership.model';
import { OrganizationRole } from 'app/shared/model/enumerations/organization-role.model';

export interface ICohortAccess {
  id?: number;
  role?: OrganizationRole;
  cohort?: ICohort;
  membership?: IMembership;
}

export const defaultValue: Readonly<ICohortAccess> = {};
