import { IDataAnalysisAccess } from 'app/shared/model/data-analysis-access.model';
import { ICooperationCohort } from 'app/shared/model/cooperation-cohort.model';
import { IOrganization } from 'app/shared/model/organization.model';

export interface IDataAnalysis {
  id?: number;
  name?: string;
  dataAnalysisID?: string;
  description?: any;
  notes?: any;
  dataAnalysisAccesses?: IDataAnalysisAccess[];
  cooperationCohorts?: ICooperationCohort[];
  organization?: IOrganization;
}

export const defaultValue: Readonly<IDataAnalysis> = {};
